import React from "react";
import {
  withStyles,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import { isUndefined, isNull, isEmpty, lte, isEqual } from "lodash";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import ErrorGetBalance from "./ErrorGetBalance";
import { formatCurrencyValue } from "../utils";
import { useSelector } from "react-redux";
//import { totalDeuda } from "../PagosForm";

const styles = (theme) => ({
  labelRoot: {
    padding: "0",
    fontSize: "13px",
    fontfamily: 'Red Hat Display',
    fontWeight: "Bold",
    color: "#707070",
    lineHeight: "40px",
  },
  formGroup: {
    alignItems: "center",
  },
  labelRadioFullWidthRoot: {
    width: "100%",
    marginLeft: 0,
    marginRight: 0,
  },
  labelRadio: {
    border: "1px solid #CCCCCC",
    borderRadius: theme.shape.borderRadius,
    padding: "8px 0",
    textAlign: "center",
    width: 140,
    fontWeight: theme.typography.fontWeightMedium,
    color: "#797979",
  },
  labelRadioSelected: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  fullWidth: {
    width: "100%",
  },
  hiddenRadios: {
    display: "none",
  },
  textInputRoot: {
    padding: "0",
    fontSize: "13px",
    fontfamily: 'Red Hat Display',
    fontWeight: "Bold",
    color: "#707070",
    lineHeight: "40px",
  },
  textInputInput: {
    width: 100,
  },
  textInputInputAlignCenter: {
    textAlign: "center",
  },
  textInputDisabled: {
    color: "inherit",
  },
  textInputUnderline: {
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&$textInputDisabled:before": {
      borderBottom: "1px solid #00000061",
    },
  },
  textInputUnderlineSelected: {
    "&:after": {
      transform: "scaleX(1)",
    },
  },
  typographyFavor: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
    color: "#007A6E",
    width: "100%",
  },
});

const allowedMinMaxAndEmptyValueInput = ({ value, floatValue }) =>
  ((isUndefined(floatValue) || isNull(floatValue)) && isEmpty(value)) ||
  lte(floatValue, 999999.99);

// eslint-disable-next-line react/prop-types
const NumberFormatInput = ({ inputRef, onChange, name, ...other }) => {
  return (
    <NumberFormat
      {...other}
      allowNegative={false}
      getInputRef={inputRef}
      onValueChange={onChange}
      decimalSeparator=","
      isNumericString
      placeholder="$ 0,00"
      prefix="$ "
      decimalScale={2}
      // fixedDecimalScale
      isAllowed={allowedMinMaxAndEmptyValueInput}
    />
  );
};

const PaymentsInput = ({
  classes,
  amountToPayDescription,
  isDisabledFullPayment,
  fullPaymentValue,
  favorValue,
  partialPaymentValue,
  handleChangeRadio,
  handleChangeInput,
  handleBlur,
  hiddenRadios,
  errorGetBalance,
  errorInput,
  helperTextErrorInput,
}) => {

  const totalDeuda = useSelector((state) => { return state.debtReducer.debt });

  const labelTotalPayment = `Total ${formatCurrencyValue(totalDeuda)}`;
  const textFavor = `Saldo a favor ${formatCurrencyValue(favorValue)}`;

  return (
    <FormControl component="fieldset" className={classes.fullWidth}>
      <FormLabel
        component="legend"
        focused={false}
        className={classes.labelRoot}
      >
        IMPORTE A PAGAR
      </FormLabel>
      <FormGroup row className={classes.formGroup}>
        {errorGetBalance ? (
          <ErrorGetBalance paragraph />
        ) : (
          <>
            <FormControlLabel
              checked={ true }
              //checked={isEqual(amountToPayDescription, "fullPayment")}
              onChange={handleChangeRadio}
              name="amountToPayDescription"
              onBlur={handleBlur}
              classes={{
                root: classNames({
                  [classes.labelRoot]: hiddenRadios,
                }),
                label: classNames(classes.labelRoot, {
                  [classes.fullWidth]: hiddenRadios,
                  [classes.labelRoot]: isEqual(
                    amountToPayDescription,
                    "fullPayment"
                  ),
                }),
              }}
              control={
                <Radio
                  color="primary"
                  className={classNames({
                    [classes.hiddenRadios]: hiddenRadios,
                  })}
                />
              }
              label={labelTotalPayment}
            />
            {favorValue !== 0 && hiddenRadios && (
              <Typography
                align="center"
                paragraph
                className={classes.typographyFavor}
              >
                {textFavor}
              </Typography>
            )}
          </>
        )}
      </FormGroup>
      {/*
      <FormGroup row className={classes.formGroup}>
        <FormControlLabel
          value="partialPayment"
          checked={isEqual(amountToPayDescription, "partialPayment")}
          onChange={handleChangeRadio}
          name="amountToPayDescription"
          onBlur={handleBlur}
          classes={{
            root: classNames({
              [classes.labelRoot]: hiddenRadios,
            }),
            label: classNames(classes.labelRoot, {
              [classes.fullWidth]: hiddenRadios,
              [classes.labelRoot]: isEqual(
                amountToPayDescription,
                "partialPayment"
              ),
            }),
          }}
          control={
            <Radio
              color="primary"
              className={classNames({ [classes.hiddenRadios]: hiddenRadios })}
            />
          }
          label="Otro importe"
        />
        <TextField
          id="partialPayment"
          name="partialPayment"
          value={partialPaymentValue}
          onChange={({ floatValue }) =>
            handleChangeInput("partialPayment", floatValue)
          }
          onBlur={handleBlur}
          fullWidth={hiddenRadios}
          InputProps={{
            inputComponent: NumberFormatInput,
            classes: {
              root: classNames(classes.textInputRoot, {
                [classes.textInputUnderlineSelected]: isEqual(
                  amountToPayDescription,
                  "partialPayment"
                ),
              }),
              underline: classes.textInputUnderline,
              input: classNames(classes.textInputInput, {
                [classes.textInputInputAlignCenter]: hiddenRadios,
              }),
            },
          }}
          gutterBottom={hiddenRadios}
          error={errorInput}
          helperText={helperTextErrorInput}
        />
        </FormGroup>*/}
    </FormControl>
  );
};

PaymentsInput.defaultProps = {
  partialPaymentValue: null,
  helperTextErrorInput: "",
};

export default withStyles(styles)(PaymentsInput);
