import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import MediaQuery from "react-responsive";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../Navbar/Navbar";
import Contact from "../Contact/Contact";
import PagosForm from "./PagosForm";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setDebt } from "../../reducers/debtReducer"
import "../Pagos/pagos.css";
import { getInfo, postInfo } from "../../utils/backendRequest";
import { useSelector } from "react-redux";
import { errorAlert } from "../../utils/alerts/alerts";
import Swal from "sweetalert2";
import Loading from "../../utils/Loading";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDivider-middle": {
      backgroundColor: "00748b",
      border: "1px solid #00748b",
    },
  },
}));

export default function Pagos(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [totalDeuda, setTotalDeuda] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const contrato = useSelector((state) => state.debtReducer.contract);
  useEffect(() => {
    let contract = sessionStorage.getItem('contractSelected');
    let nroContrato = contrato? contrato :contract;
    postInfo(`/consultDebt`, {
      prepaga: 4,
      contrato: nroContrato.trim(),
      fecha: moment().format()
    }, (body)=> {
      const {totalDeuda} = body;
      dispatch(setDebt(totalDeuda));
    }, ()=>{
      Swal.fire("¡Ups! Se produjo un inconveniente en la consulta de deuda. Reintente en breve.", "", "info")
          .then(r => props.history.push("/contratos"));
    })
    setLoading(false);
  }, []);

  return (
    <div className={classes.root}>
      <MediaQuery maxDeviceWidth={767}>
        {/* Small devices - Portrait */}
        <Navbar />
        <div id="pagoOnline" style={{ width: "100%", display: "block",marginTop: '60px' }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={4}>
            <Grid item xs>
              <NavLink exact to="/contratos" className="volver">
                <button
                  type="submit"
                  className="btn btn-primary btn-volver"
                  style={{ position: "absolute", left: "62%", top: "16.5%" }}
                >
                  Volver a Tramites
                </button>
              </NavLink>
              <h4>Pago online</h4>
              <Divider variant="middle" className="divider" />
            </Grid>
          </Box>
        </div>
        <div style={{ width: "100%" }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={4}>
            <Grid item xs={12}>
              {isLoading ? (
                <div className="App">Loading...</div>
              ) : (
                <PagosForm totalDeuda={totalDeuda} />
              )}
            </Grid>
          </Box>
        </div>
        <Contact />
      </MediaQuery>
      <MediaQuery minDeviceWidth={768} and maxDeviceWidth={819}>
        <Navbar />
        <div id="pagoOnline" style={{ width: "100%", display: "block",marginTop: '83px' }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={4}>
            <Grid item xs={7}>
              <NavLink exact to="/contratos" className="volver">
                <button
                  type="submit"
                  className="btn btn-primary btn-volver"
                  style={{ position: "absolute", left: "68%", top: "16.5%" }}
                >
                  Volver a Tramites
                </button>
              </NavLink>
              <h4>Pago online</h4>
              <Divider variant="middle" className="divider" />
            </Grid>
          </Box>
        </div>
        <div style={{ width: "100%" }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={8}>
            <Grid item xs={8}>
              {isLoading ? (
                <div className="App">Loading...</div>
              ) : (
                <PagosForm totalDeuda={totalDeuda} />
              )}
            </Grid>
          </Box>
        </div>
        <Contact />
      </MediaQuery>

      <MediaQuery minDeviceWidth={820} and maxDeviceWidth={820}>
        <Navbar />
        <div id="pagoOnline" style={{ width: "100%", display: "block",marginTop: '83px' }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={4}>
            <Grid item xs={7}>
              <NavLink exact to="/contratos" className="volver">
                <button
                  type="submit"
                  className="btn btn-primary btn-volver"
                  style={{ position: "absolute", left: "61%", top: "9.5%" }}
                >
                  Volver a Tramites
                </button>
              </NavLink>
              <h4>Pago online</h4>
              <Divider variant="middle" className="divider" />
            </Grid>
          </Box>
        </div>
        <div style={{ width: "100%" }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={8}>
            <Grid item xs={8}>
              {isLoading ? (
                <div className="App">Loading...</div>
              ) : (
                <PagosForm totalDeuda={totalDeuda} />
              )}
            </Grid>
          </Box>
        </div>
        <Contact />
      </MediaQuery>

      <MediaQuery minDeviceWidth={821} and maxDeviceWidth={852}>
        <Navbar />
        <div id="pagoOnline" style={{ width: "100%", display: "block",marginTop: '83px' }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={4}>
            <Grid item xs={7}>
              <NavLink exact to="/contratos" className="volver">
                <button
                  type="submit"
                  className="btn btn-primary btn-volver"
                  style={{ position: "absolute", left: "68%", top: "16.5%" }}
                >
                  Volver a Tramites
                </button>
              </NavLink>
              <h4>Pago online</h4>
              <Divider variant="middle" className="divider" />
            </Grid>
          </Box>
        </div>
        <div style={{ width: "100%" }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={8}>
            <Grid item xs={8}>
              {isLoading ? (
                <div className="App">Loading...</div>
              ) : (
                <PagosForm totalDeuda={totalDeuda} />
              )}
            </Grid>
          </Box>
        </div>
        <Contact />
      </MediaQuery>
      <MediaQuery minDeviceWidth={853} and maxDeviceWidth={853}>
        <Navbar />
        <div id="pagoOnline" style={{ width: "100%", display: "block",marginTop: '83px' }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={4}>
            <Grid item xs={7}>
              <NavLink exact to="/contratos" className="volver">
                <button
                  type="submit"
                  className="btn btn-primary btn-volver"
                  style={{ position: "absolute", left: "64%", top: "9.5%" }}
                >
                  Volver a Tramites
                </button>
              </NavLink>
              <h4>Pago online</h4>
              <Divider variant="middle" className="divider" />
            </Grid>
          </Box>
        </div>
        <div style={{ width: "100%" }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={8}>
            <Grid item xs={8}>
              {isLoading ? (
                <div className="App">Loading...</div>
              ) : (
                <PagosForm totalDeuda={totalDeuda} />
              )}
            </Grid>
          </Box>
        </div>
        <Contact />
      </MediaQuery>
      <MediaQuery minDeviceWidth={854} and maxDeviceWidth={911}>
        <Navbar />
        <div id="pagoOnline" style={{ width: "100%", display: "block",marginTop: '83px' }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={4}>
            <Grid item xs={7}>
              <NavLink exact to="/contratos" className="volver">
                <button
                  type="submit"
                  className="btn btn-primary btn-volver"
                  style={{ position: "absolute", left: "68%", top: "16.5%" }}
                >
                  Volver a Tramites
                </button>
              </NavLink>
              <h4>Pago online</h4>
              <Divider variant="middle" className="divider" />
            </Grid>
          </Box>
        </div>
        <div style={{ width: "100%" }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={8}>
            <Grid item xs={8}>
              {isLoading ? (
                <div className="App">Loading...</div>
              ) : (
                <PagosForm totalDeuda={totalDeuda} />
              )}
            </Grid>
          </Box>
        </div>
        <Contact />
      </MediaQuery>
      <MediaQuery minDeviceWidth={912} and maxDeviceWidth={912}>
        <Navbar />
        <div id="pagoOnline" style={{ width: "100%", display: "block",marginTop: '83px' }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={4}>
            <Grid item xs={7}>
              <NavLink exact to="/contratos" className="volver">
                <button
                  type="submit"
                  className="btn btn-primary btn-volver"
                  style={{ position: "absolute", left: "65%", top: "8.5%" }}
                >
                  Volver a Tramites
                </button>
              </NavLink>
              <h4>Pago online</h4>
              <Divider variant="middle" className="divider" />
            </Grid>
          </Box>
        </div>
        <div style={{ width: "100%" }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={8}>
            <Grid item xs={8}>
              {isLoading ? (
                <div className="App">Loading...</div>
              ) : (
                <PagosForm totalDeuda={totalDeuda} />
              )}
            </Grid>
          </Box>
        </div>
        <Contact />
      </MediaQuery>
      <MediaQuery minDeviceWidth={913} and maxDeviceWidth={991}>
        <Navbar />
        <div id="pagoOnline" style={{ width: "100%", display: "block",marginTop: '83px' }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={4}>
            <Grid item xs={7}>
              <NavLink exact to="/contratos" className="volver">
                <button
                  type="submit"
                  className="btn btn-primary btn-volver"
                  style={{ position: "absolute", left: "68%", top: "16.5%" }}
                >
                  Volver a Tramites
                </button>
              </NavLink>
              <h4>Pago online</h4>
              <Divider variant="middle" className="divider" />
            </Grid>
          </Box>
        </div>
        <div style={{ width: "100%" }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={8}>
            <Grid item xs={8}>
              {isLoading ? (
                <div className="App">Loading...</div>
              ) : (
                <PagosForm totalDeuda={totalDeuda} />
              )}
            </Grid>
          </Box>
        </div>
        <Contact />
      </MediaQuery>

      <MediaQuery minDeviceWidth={992} and maxDeviceWidth={1023}>
        <Navbar />
        <div id="pagoOnline" style={{ width: "100%", display: "block",marginTop: '83px' }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={4}>
            <Grid item xs={7}>
              <NavLink exact to="/contratos" className="volver">
                <button
                  type="submit"
                  className="btn btn-primary btn-volver"
                  style={{ position: "absolute", left: "68%", top: "16.5%" }}
                >
                  Volver a Tramites
                </button>
              </NavLink>
              <h4>Pago online</h4>
              <Divider variant="middle" className="divider" />
            </Grid>
          </Box>
        </div>
        <div style={{ width: "100%" }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={8}>
            <Grid item xs={6}>
              {isLoading ? (
                <div className="App">Loading...</div>
              ) : (
                <PagosForm totalDeuda={totalDeuda} />
              )}
            </Grid>
          </Box>
        </div>
        <Contact />
      </MediaQuery>

      <MediaQuery query="(min-device-width: 1024px) and (max-device-width: 1024px) and (min-device-height: 600px) and (max-device-height: 600px) " >
        <Navbar />
        <div id="pagoOnline" style={{ width: "100%", display: "block",marginTop: '83px' }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={4}>
            <Grid item xs={7}>
              <NavLink exact to="/contratos" className="volver">
                <button
                  type="submit"
                  className="btn btn-primary btn-volver"
                  style={{ position: "absolute", left: "62%", top: "18.5%"}}
                >
                  Volver a Tramites
                </button>
              </NavLink>
              <h4>Pago online</h4>
              <Divider variant="middle" className="divider" />
            </Grid>
          </Box>
        </div>
        <div style={{ width: "100%" }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={8}>
            <Grid item xs={6}>
              {isLoading ? (
                <div className="App">Loading...</div>
              ) : (
                <PagosForm totalDeuda={totalDeuda} />
              )}
            </Grid>
          </Box>
        </div>
        <Contact />
      </MediaQuery>

      <MediaQuery query="(min-device-width: 1024px) and (max-device-width: 1024px) and (min-device-height: 1366px) and (max-device-height: 1366px) " >
        <Navbar />
        <div id="pagoOnline" style={{ width: "100%", display: "block",marginTop: '83px' }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={4}>
            <Grid item xs={7}>
              <NavLink exact to="/contratos" className="volver">
                <button
                  type="submit"
                  className="btn btn-primary btn-volver"
                  style={{ position: "absolute", left: "63%", top: "8.5%"}}
                >
                  Volver a Tramites
                </button>
              </NavLink>
              <h4>Pago online</h4>
              <Divider variant="middle" className="divider" />
            </Grid>
          </Box>
        </div>
        <div style={{ width: "100%" }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={8}>
            <Grid item xs={6}>
              {isLoading ? (
                <div className="App">Loading...</div>
              ) : (
                <PagosForm totalDeuda={totalDeuda} />
              )}
            </Grid>
          </Box>
        </div>
        <Contact />
      </MediaQuery>

      <MediaQuery minDeviceWidth={1025}>
        <Navbar />
        <div id="pagoOnline" style={{ width: "100%", display: "block",marginTop: '83px' }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={4}>
            <Grid item xs={7}>
              <NavLink exact to="/contratos" className="volver">
                <button
                  type="submit"
                  className="btn btn-primary btn-volver"
                  style={{ position: "absolute", left: "68%", top: "16.5%" }}
                >
                  Volver a Tramites
                </button>
              </NavLink>
              <h4>Pago online</h4>
              <Divider variant="middle" className="divider" />
            </Grid>
          </Box>
        </div>
        <div style={{ width: "100%" }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff" p={8}>
            <Grid item xs={6}>
              {isLoading ? (
                <div className="App">Loading...</div>
              ) : (
                <PagosForm totalDeuda={totalDeuda} />
              )}
            </Grid>
          </Box>
        </div>
        <Contact />
      </MediaQuery>
    </div>
  );
}
