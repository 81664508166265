/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect,useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import { Formik,Form } from 'formik'
import ReCAPTCHA from "react-google-recaptcha";
import {titleFormAsesoremiento} from './data'
import schemaFormAsesoramiento from './schemaFormAsesoramiento'
import TextInput from './InputFormAsesoramiento/TextInput'
import Select from './InputFormAsesoramiento/Select'
import Number from './InputFormAsesoramiento/Number'
import Email from './InputFormAsesoramiento/Email'
import Date from './InputFormAsesoramiento/Date'
import TextArea from './InputFormAsesoramiento/TextArea'
import { adviceActions ,adviceReset} from '../../../actions/adviceActions';
import { authLoginActions,authLoginReset } from '../../../actions/authLoginActions'
import {errorAlert,messageAlert} from '../../../utils/alerts/alerts'
import Loading from '../../Loading/Loading'
import {SMG_CELLS, SITE_KEY} from '../../../constants/index'
import './formAsesoramiento.css'
import TextInputPasaporte from './InputFormAsesoramiento/TextInpuPasaporte';

const FormAsesoramiento = () => {
    const recaptchaRef = React.createRef();
    const dispatch =   useDispatch()
    const responseQuery = useSelector( state => state.advice.data)
    const errorQuery = useSelector( state => state.advice.error)
    const loadingQuery = useSelector(state=>state.advice.loading)
    const user = useSelector(state =>state.authLogin.data)
    const errorAuthLogin = useSelector(state =>state.authLogin.error)
    const loadingAuthLogin = useSelector(state =>state.authLogin.loading)
    const [responseReCaptcha,setResponseReCaptcha] = useState(null)
    const [dataCompleted,setDataCompleted] = useState("")
    const [valuesForm, setValuesForm] = useState("")
    const initialValues = {
        nombre: "",
        apellido:"",
        docTipo:"DU",
        docNum:"",
        codigo_nacional:"",
        numero:"",
        email:"",
        fecha_probable_parto: "",
        observacion: ""
     }

useEffect(() => {
    dispatch(adviceReset())
    dispatch(authLoginReset()) 
    if(responseQuery){
        messageAlert("success",SMG_CELLS,responseQuery.mensaje)
    }

        if(errorQuery){
        errorAlert("Aviso",errorQuery);
    } 
    
        if(errorAuthLogin){
        errorAlert("¡Ups! algo no anda bien",errorAuthLogin);
    } 
},[responseQuery,errorQuery,errorAuthLogin]);

const onSubmitWithReCAPTCHA = async (values) => {

    const tokenRc = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    if(tokenRc){
        const data = {
            recaptchaResponse: tokenRc,
            uri:'/advice',
            ...values
        }
        dispatch(authLoginActions(data))
    } else {
        errorAlert("Tuvimos un problema procesando tu solicitud","Volve a intentarlo en unos minutos por favor.");
    }
     
  }


useEffect(()=>{
  if(user!==null){  
    const data ={
        user:user,
        ...valuesForm,
    }

setDataCompleted(data)
}
},[user])


const submitFormAsesoramiento = async (values)=>{
    const formattedValues = {
        ...values,
        docNum: values.docNum ? values.docNum.toString() : "", 
    };

    await onSubmitWithReCAPTCHA(formattedValues);
    setValuesForm(formattedValues)
}


const handleKeyPress = (event) => {
    const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/;
    if (!regex.test(event.key)) {
      event.preventDefault();                    
    }
};

const handleKeyPressNumber = (event) => {

    if (event.key === "Backspace" || event.key === "Delete" || event.key.startsWith("Arrow")) {
        return;
    }

    const regex = /^[0-9]*$/;
    if (!regex.test(event.key)) {
      event.preventDefault();                     
    }
};

return (<div className = "containerLoadingForm">
        <div className = "containerFormAsesoramiento">
            <h2 className = "titleAsesoramientoInicio">{titleFormAsesoremiento}</h2>
            <Formik
                initialValues = {initialValues}
                validationSchema = {schemaFormAsesoramiento}
                onSubmit = {(values, { resetForm }) => {
                    if(values.docTipo.toString() === 'PA'){
                        /* if(typeof values.docNum == "number" && values.docNum.toString().length != 9){ */
                        if (!values.docNum || values.docNum.toString().length !== 9) {  
                            messageAlert('info', 'Aviso', 'El pasaporte ingresado debe tener 9 dígitos. Por favor, verifica los datos e intenta nuevamente.');
                            return;
                        }

                    }else{
                        if(values.docTipo.toString() === 'DU'){
                            /* if(typeof values.docNum == "number" && values.docNum.toString().length != 8){ */
                            if (!values.docNum || values.docNum.toString().length !== 8) {    
                                messageAlert('info', 'Aviso', 'El DNI ingresado debe tener 8 dígitos. Por favor, verifica los datos e intenta nuevamente.');
                                return;
                            }
                        }
                    }

                    if(typeof values.numero == "number" && values.numero.toString().length != 8){
                        messageAlert('info', 'Aviso', 'El teléfono ingresado debe tener 8 dígitos. Por favor, verifica los datos e intenta nuevamente.');
                        return;
                    }

                    if(typeof values.codigo_nacional == "number" && (values.codigo_nacional.toString().length < 2 ||
                        values.codigo_nacional.toString().length > 4)){
                        messageAlert('info', 'Aviso', 'El Código de área debe tener entre 2 y 4 dígitos. Por favor, verifica los datos e intenta nuevamente.');
                        return;
                    }
                    submitFormAsesoramiento(values)
                    resetForm();
                }
                }
            >

            {(values) => (
                <Form className = "formRegistration" >
                {(loadingQuery || loadingAuthLogin) && <Loading/> }
                        <div className = "containerNameLastName">
                            <TextInput name ="nombre" placeholder = "Nombre" mright={8} required={true} onKeyDown={handleKeyPress}/>
                            <TextInput name ="apellido" placeholder = "Apellido" mleft={8} required={true} onKeyDown={handleKeyPress}/>
                        </div>    
                        
                        <div className = "containerDocument">
                            <Select name= "docTipo" onChange={(e) => {
                                values.setFieldValue("docTipo", e.target); // Actualiza el valor del select
                                values.setFieldValue("docNum", "");    // Resetea el campo docNum
                                values.handleChange(e);
                            }}>
                                <option value = "DU">DNI</option>
                                <option value = "PA">Pasaporte</option>
                            </Select>

                            {values.values.docTipo === "DU" ? (
                                <Number name="docNum" placeholder="Número" required={true} widthInput={"100%"} maxLength={8} onKeyDown={handleKeyPressNumber}/>
                            ) : (
                                <TextInputPasaporte name="docNum" placeholder="Número de Pasaporte" required={true} widthInput={"100%"}/>
                            )}

                        </div>

                        <div className = "containerCodAreTelefono">
                            <Number name ="codigo_nacional" widthInput = {80} placeholder = "Cod. Área" mright={10} required={true}/>
                            <Number name ="numero"  placeholder = "Teléfono" required={true}/>
                        </div>

                        <Email name = "email" placeholder = "E-mail"/>
                        <Date name ="fecha_probable_parto" label = "Fecha Prob. Parto"/>  
                        <TextArea name="observacion"  placeholder="Comentarios (Opcional)"  heightInput ={90} />
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            style={{ marginTop: "5%" }}
                            size="invisible"
                            sitekey={SITE_KEY}
                        
                        /> 
                        <div className ="containerBtnAsesoramientoInicio">

                            <button className = "btnFormAsesoramiento" type="submit">Enviar Datos</button>

                        </div>
                    </Form>
                )}
            </Formik>
        </div>
        </div>
    )
}

export default FormAsesoramiento
