/**
 * @author Juan Carlos Cancela <cancela.juancarlos@gmail.com>
 */
/* eslint-disable import/prefer-default-export */

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_CAPTCHA_SUCCESS = "AUTH_CAPTCHA_SUCCESS";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_REFRESH_TOKEN = "AUTH_REFRESH_TOKEN";
export const LOCALSTORAGE_AUTH_KEY = "smg_auth";
export const AUTH_MODEL_PATCH = "AUTH_MODEL_PATCH";
// SEPELIOS
export const COBERTURAS_RESPONSE = 'COBERTURAS_RESPONSE';
export const COBERTURA_ERROR = "COBERTURA_ERROR";
//IMAGEN CARROUSELS
export const FETCH_IMAGES_CAROUSEL_NEWS_SUCCESS =
  "FETCH_IMAGES_CAROUSEL_NEWS_SUCCESS";
export const FETCH_IMAGES_CAROUSEL_NEWS_ERROR =
  "FETCH_IMAGES_CAROUSEL_NEWS_ERROR";
// export const URL_SERVER = 'https://mobileqa.swissmedical.com.ar';
// export const urlMobileQA = 'mobileqa.swissmedical.com.ar';
export const LIMITE_INTENTOS_SUPERADOS = "1005";
export const USUARIO_NO_REGISTRADO = "1000";
export const USUARIO_DADO_DE_BAJA = "1001";
export const ALTA_FUTURA = "1002";

// REINTEGROS Y PRESUPUESTOS
export const LOCALSTORAGE_AUTH_CAPTCHA = "LOCALSTORAGE_AUTH_CAPTCHA";
export const LOCALSTORAGE_AUTH_API = "LOCALSTORAGE_AUTH_API";

// inicio Form Asesoramiento
export const REQUIRED_NAME_ASESORAMIENTO = "El campo es requerido";
export const REQUIRED_LAST_NAME_ASESORAMIENTO = "El campo es requerido";
export const REQUIRED_NUMBER_DOCUMENT_ASESORAMIENTO ="El campo es requerido";
export const REQUIRED_DATE_ASESORAMIENTO = "El campo es requerido";
export const REQUIRED_EMAIL_ASESORAMIENTO = "El campo es requerido";
export const VALIDATE_EMAIL_ASESORAMIENTO = "El E-mail no es valido";
export const REQUIRED_COD_AREA_ASESORAMIENTO = "Requerido";
export const REQUIRED_TELEFONO_ASESORAMIENTO = "El campo es requerido";
export const DATE_MIN_ASESORAMIENTO = "No se puede cargar esa fecha de parto.";
export const TEXT_POPUP = 'Pronto un asesor se contactará con usted';
export const REQUIERED_TIPO_DOCUMENTO_ASESORAMIETNO = "El tipo de documento es requerido.";
export const MIN_NUMERO_ASESORAMIENTO = "Ocho digitos para DNI. Nueve para PA.";
export const SMG_CELLS = "SMG CELLS";
export const REQUIERED_FIELD_GENERIC = "El campo es requerido";
export const MIN_NUMTEL_ASESORAMIENTO = "Seis  digitos";
export const MIN_NUMTEL_ASESORAMIENTO_BUENOS_AIRES = "Ocho  digitos";
export const MIN_NUMTEL_ASESORAMIENTO_INTERIOR = "Seis a ocho digitos";
export const MIN_CODPAIS_ASESORAMIENTO = "El código de país es incorrecto";
export const MIN_CODAREA_ASESORAMIENTO = "Entre 2 y 4 digitos";
export const ENVIRONMENT_URL = `${document.location.origin}`;
export const WHATSAPP_URL = 'https://api.whatsapp.com/send?phone=5491121888332';
export const REACT_APP_GOOGLE_TAGMANAGER_ID = 'GTM-TR95GH4';
export const SITE_KEY = '6LeRCyMdAAAAAIba7iqxpHCoU2YE8LjEpX_OneLJ';
export const ENTIDAD = 9;
export const CANAL = 1;
