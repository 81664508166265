import React, { createRef, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { withStyles, Grid, Button } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "@material-ui/core/Modal";
import SelectorCard from "./components/SelectorCard";
import AdornmmentInputs from "./components/AdormmentInputs";
import PaymentsInput from "./components/PaymentsInput";
import SmgDatePicker from "./utils/smgDatepicker";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import MediaQuery from "react-responsive";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";

import {
  isEmpty,
  map,
  isEqual,
  isUndefined,
  get,
  endsWith,
  trimEnd,
  replace,
  split,
  toString,
} from "lodash";
import {
  cardTypes,
  getCardTypeByType,
  getCardTypeById,
  formatCardNumber,
  formatCurrencyValue,
  hasError,
  getHelperTextError,
} from "./utils";

import Loading from "../../utils/Loading";


import "react-credit-cards/es/styles-compiled.css";
import { useSelector } from "react-redux";
import { postInfo } from "../../utils/backendRequest";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../constants";


// Función "añadir cero".
function addZero(x, n) {
  while (x.toString().length < n) {
    x = "0" + x;
  }
  return x;
}

function randomInt() {
  let d = new Date();
  let h = addZero(d.getHours(), 2);
  let m = addZero(d.getMinutes(), 2);
  let s = addZero(d.getSeconds(), 2);
  let ms = addZero(d.getMilliseconds(), 3);
  let uidd = `${h}${m}${s}${ms}`;
  return uidd;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root": {
      padding: "0",
      fontSize: "14px",
      fontfamily: 'Red Hat Display',
      fontWeight: "400",
      linHeight: "1",
      letterSpacing: "0.00938em",
      "@media (min-width:768px)": {
        fontSize: "12px",
      },
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
    },
    "& .MuiSvgIcon-root": {
      color: "#00758d",
    },
  },
  ButtonP: {
    fontFamily: 'Red Hat Display',
    color: "#fff",
    backgroundImage: "linear-gradient(to right, #00758d 0%, #00758d 100%)",
    padding: "14px 34px",
    letterSpacing: "1px",
    margin: "0",
    fontSize: "13px",
    fontWeight: "500",
    borderRadius: "25px",
    transition: "all 0.5s linear",
    border: "0",
    backgroundColor: " #00758d",
    borderColor: "#00758d",
    boxShadow: "none",
    width: "auto",
  },
  block: {
    display: "block",
  },
  labelRoot: {
    padding: "0",
    fontSize: "13px",
    fontfamily: 'Red Hat Display',
    fontWeight: "Bold",
    color: "#707070",
    lineHeight: "40px",
  },
  title: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
    color: "#363636",
  },
  inputCommon: {
    "& > div": {
      maxWidth: 380,
      height: "40px",
    },
  },
  inputCardDocumentType: {
    "& > div": {
      maxWidth: 160,
      height: "40px",
    },
  },
  inputCardDocumentNumber: {
    "& > div": {
      maxWidth: 200,
      height: "40px",
    },
  },
  inputCardExpiryDate: {
    "& > div": {
      width: 161,
      maxWidth: 161,

      height: "40px",
    },
  },
  inputCardSecurity: {
    "& > div": {
      width: 100,
      maxWidth: 100,

      height: "40px",
    },
  },
  textError: {
    color: "#e4002b",
    fontSize: "13px",
    marginTop: "-11px",
  },
  textErrorMetodoPago: {
    color: "#e4002b",
    fontSize: "13px",
  },
  dateText: {
    color: "#363636",
    fontWeight: "500",
    paddingLeft: "3px",
    marginTop: "-8px",
    marginBottom: "1px",
  },
  fecha: {
    padding: "0.5em",
    textAlign: "center",
  },
  contentFooterModal: {
    display: "flex",
    justifyContent: "space-around",
  },
  contentModal: {
    textAlign: "center",
  },
  contentTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
  },
  contentAmount: {
    fontSize: "inherit",
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
  },
  labelCheck: {
    "& > span": {
      padding: "0",
      fontSize: "13px",
      fontfamily: 'Red Hat Display',
      fontWeight: "Bold",
      color: "#707070",
      lineHeight: "40px",
    },
  },
  check: {
    "& > span": {
      color: "#e4002b",
      "&$checked": {
        color: "#e4002b",
      },
    },
  },
}));
const validationSchema = (card) =>
  Yup.object().shape({
    cardSelected: Yup.string()
      .oneOf(map(cardTypes, "type"), "Metodo de pago inválido")
      .required("Seleccioná un metodo de pago"),
    cardHolder: Yup.string()
      .max(62, "Cantidad maxima de caracteres")
      .test({
        name: "onlyLetterAndNotTwoWhiteSpace",
        message: "Algunos caracteres no son validos",
        test: (value) => {
          const rgx = /^[a-zA-ZñÑ]{1,20}(?:\s[a-zA-ZñÑ]{1,20}){0,9}$/g;
          return isEmpty(value) || value.search(rgx) !== -1;
        },
      })
      .required("Campo requerido"),
    documentType: Yup.string()
      .oneOf(map(tiposDocumentos, "value"), "Tipo de documento inválido")
      .required("Campo requerido"),
    documentNumber: Yup.string()
      .when("documentType", (val, schema) =>
        isEqual(val, "DNI")
          ? schema.min(6, "Documento inválido").max(8, "Documento inválido")
          : schema.length(11, "Documento inválido")
      )
      .required("Campo requerido"),
    cardNumber: Yup.string()
      .length(
        !isUndefined(card)
          ? get(card, ["length"])
          : get(cardTypes, [0, "length"]),
        "La cantidad de dígitos ingresados es incorrecta"
      )
      .required("Campo requerido"),
    cardExpiryDate: Yup.date().required("Campo Requerido"),
    cardSecurity: Yup.string()
      .length(
        !isUndefined(card)
          ? get(card, ["codeSecurity", "length"])
          : get(cardTypes, [0, "codeSecurity", "length"]),
        "El CVV ingresado es incorrecto"
      )
      .required("Campo requerido"),

    cuotas: Yup.number()
      .oneOf(map(cantCuotas, "value"))
      .required("Campo requerido"),
    email: Yup.mixed().when("checked", {
      is: true,
      then: Yup.mixed()
        .required("Este campo es requerido")
        .test({
          message: "Por favor, ingresá un mail válido",
          test: (value) => {
            var pattern = new RegExp(
              /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            return isEmpty(value) || value.search(pattern) !== -1;
          },
        }),
      otherwise: Yup.mixed().notRequired(),
    }),
  });

const tiposDocumentos = [
  {
    value: "DNI",
    label: "DNI",
  }
];

const cantCuotas = [
  {
    value: 1,
    label: 1,
  }
];

const MIN_PAYMENT_VALUE = 5;

const cardNumberInput = createRef();
const expiryDateInput = createRef();
const cvcInput = createRef();
const cardHolderInput = createRef();

function PagosForm(props) {
  const classes = useStyles();


  let history = useHistory();

  const [state, setState] = React.useState({
    show: false,
    isOpen: false,
    isOpenLoading: false,
    messageModal: "",
    cardType: undefined,
    filterErrors: {},
    open: false,
    type: 500,
    title: "",
  });

  //Ini

  window.addEventListener("wheel", { passive: true });

  //Modals

  const handleClose = () => {
    setState({ isOpen: false });
  };

  //Loading
  function handleOpenLoading() {
    setState({ isOpenLoading: true });
  }

  function handleCloseLoading() {
    setState({ isOpenLoading: false });
  }

  const handleBlurCardHolder = (e, setFieldTouched, setFieldValue) => {
    const value = e.target.value || "";
    let activateValidationSetFieldTouched = true;

    // Eliminar espacios en blanco para el ultimo caracters si lo hay
    if (endsWith(value, " ")) {
      const valueTrimEnd = trimEnd(value);
      // Se activa las validaciones en el setFieldValue (3er parametro true)
      // y en el setFielTouched se omiten
      setFieldValue(e.target.name, valueTrimEnd, true);
      activateValidationSetFieldTouched = false;
    }

    setFieldTouched(e.target.name, true, activateValidationSetFieldTouched);
  };

  const handleChangeCardType = (e, handleChange) => {
    const cardType = getCardTypeByType(e.target.value);
    setState({ cardType });
    handleChange(e);
  };

  const handleChangeCardNumber = (e, handleChange, cardSelected) => {
    const formattedCardNumber = e.target.value || "";
    const cardNumber = formattedCardNumber.replace(/\s/g, "");
    let cursorPosition = cardNumberInput.current.selectionStart;

    cardNumberInput.current.value = formatCardNumber(cardNumber, cardSelected);

    handleChange(e);

    requestAnimationFrame(() => {
      if (document.activeElement !== cardNumberInput.current) return;
      if (cardNumberInput.current.value[cursorPosition - 1] === " ") {
        // eslint-disable-next-line operator-assignment
        cursorPosition = cursorPosition + 1;
      }
      cardNumberInput.current.setSelectionRange(cursorPosition, cursorPosition);
    });
  };

  // eslint-disable-next-line consistent-return
  const validateOnKeyDown = (evt, type = "onlyNumbers") => {
    const regexs = {
      onlyNumbers: /[0-9]/,
      onlyLetters: /[a-zA-ZñÑ]/,
    };

    const { keyCode, which, ctrlKey, key } = evt;
    const charCode = which || keyCode;

    const pressBackSpace = isEqual(charCode, 8);
    const pressTab = isEqual(charCode, 9);
    const pressSpace = isEqual(charCode, 32);
    const pressCtrlAndV = ctrlKey ? ctrlKey && isEqual(charCode, 86) : false;
    const pressKeyInlcudeInRegex = regexs[type].test(key);

    if (
      !pressBackSpace &&
      !pressTab &&
      (isEqual(type, "onlyLetters") ? !pressSpace : true) &&
      !pressCtrlAndV &&
      !pressKeyInlcudeInRegex
    ) {
      return evt.preventDefault();
    }
  };

  const handleChangePicker = (e, setFieldValue) =>
    setFieldValue("cardExpiryDate", e);

  const disabledNoCardSelected = (cardSelected) => isEqual(cardSelected, "");

  // Obtenemos el primer dia de la fecha actual
  const getMinDate = () => {
    return new Date(moment().date(1).format());
  };

  // eslint-disable-next-line consistent-return
  const validateOnPaste = (evt, type = "onlyNumbers") => {
    const regexs = {
      onlyNumbers: /^[0-9]+$/,
      onlyLetters: /^[a-zA-ZñÑ]{1,20}(?:\s[a-zA-ZñÑ]{1,20}){0,9}$/,
    };
    const clipBoard = evt.clipboardData.getData("Text");

    if (!regexs[type].test(clipBoard)) {
      return evt.preventDefault();
    }
  };

  const nroContrato = useSelector((state) => state.debtReducer.contract);
  const { debt } = useSelector((state) => state.debtReducer);

  const executePayment = (values) => {

    handleOpenLoading();
    postInfo(`/payments/${nroContrato}/payments`,
      {
        recaptchaResponse: tokenRecaptcha,
        prepaga: "4",
        inte: "01",
        tarjeta: {
          tarjetaid: cardType.id,
          numero: values.cardNumber,
          ult4Digitos: values.cardNumber.substring(values.cardNumber.length - 4),
          expira: {
            mes: moment(values.cardExpiryDate).format("MM"),
            anio: moment(values.cardExpiryDate).format("YY"),
          },
          seguridad: values.cardSecurity,
          persona: {
            nombre: values.cardHolder,
            identificacion: {
              doctipo: values.documentType,
              numero: values.documentNumber,
            },
          },
        },
        negocio: {
          iddispositivo: "127.0.0.1"
        },
        pago: {
          monto: debt,
          moneda: "ARS",
        },
        cuotas: parseInt(values.cuotas),
        emailTo: values.email,
      },
      (values) => {
        handleCloseLoading();
        const estado = values.estado;
        //const codAut = values.codAut;
        //const idTxProveedor = values.idTxProveedor;
        function ModalPago() { 
          if (estado === "aprobada") {
            /*swal({
              title: "Pago realizado!",
              text: `Estado del pago: ${estado}
              Código de Autorización: ${codAut}`,
              icon: "success",
              button: "Aceptar",
              showConfirmButton: true,
            });*/
            swal({
              title: "Pago realizado!",
              text: `${values.message}`,
              icon: "success",
              button: "Aceptar",
              showConfirmButton: true,
            });
            history.push("/contratos");
          } else if (estado === "rechazada") {
            recaptchaRef.current.reset();
            swal({
              title: "No se pudo realizar el pago",
              text: `Estado del pago: ${values.message}`,
              icon: "error",
              button: "Aceptar",
              showConfirmButton: true,
            });
          }
        }
        ModalPago();
      },
      (error, body) => {
        recaptchaRef.current.reset();
        handleCloseLoading();
        swal({
          title: "No se pudo realizar el pago",
          text: `Error: ${error || JSON.parse(body.message).message}`,
          icon: "error",
          button: "Aceptar",
          showConfirmButton: true,
        });
      }
    )
  }

  const executeMail = (
    fecha,
    nombre,
    codAutorizacion,
    idTxproveedor,
    amount,
    email
  ) => {
    postInfo('/sendMail', {
      nombres: nombre,
      codAut: codAutorizacion,
      idTxprov: idTxproveedor,
      monto: amount,
      fechaT: fecha,
      to: email,
    }, (res) => {
      console.log(res, "res mail");
    },
      (error) => {
        console.log(error, "error mail");
      });
  };

  const { email, favorValue, balanceToPay } = state;
  const { cardType } = state;
  const isMinBalance = balanceToPay < MIN_PAYMENT_VALUE;
  const [tokenRecaptcha, setTokenRecaptcha] = useState(null);
  const [validCaptcha, setValidCaptcha] = useState(false);
  const recaptchaRef = useRef(null);


  const handleRecaptchaChange = (recaptcha) => {
    setTokenRecaptcha(recaptcha);
    setValidCaptcha(true);
  };

  const handleOnExpired = () => {
    setValidCaptcha(false);
  };

  return (
    <div>
      <Modal
        isOpen={state.isOpen}
        handleClose={handleClose}
        title="Respuesta"
        mensaje={state.messageModal}
      ></Modal>

      <Loading
        isOpenLoading={state.isOpenLoading}
        handleCloseLoading={handleCloseLoading}
      ></Loading>
      <Formik
        initialValues={{
          cardSelected: "",
          cardHolder: "",
          documentType: "DNI",
          documentNumber: "",
          cardNumber: "",
          cardExpiryDate: "",
          checked: email !== "" ? true : false,
          cardSecurity: "",
          amountToPayDescription: debt,
          cuotas: 1,
          email: email || "",
        }}
        validationSchema={validationSchema(cardType)}
        onSubmit={(values) => executePayment(values, cardType)}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          setFieldValue,
          setFieldTouched,
          handleBlur,
          isValid,
          errors,
          touched,
          dirty,
        }) =>  (
          <div>
            <MediaQuery maxDeviceWidth={767}>
              <Grid
                direction="column"
                justify="center"
                alignItems="center"
                className="fondo"
              >
                <Box justifyContent="center" bgcolor="#ffff">
                  <Grid item xs>
                    <SelectorCard
                      selectedValue={values.cardSelected}
                      handleChange={(e) =>
                        handleChangeCardType(e, handleChange)
                      }
                      name="cardSelected"
                    />
                  </Grid>
                  <Grid item xs>
                    <Form.Label className={classes.labelRoot}>
                      Nombre en la tarjeta
                    </Form.Label>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="cardHolder"
                      name="cardHolder"
                      value={values.cardHolder}
                      onKeyDown={(e) => validateOnKeyDown(e, "onlyLetters")}
                      onKeyPress={(e) => validateOnKeyDown(e, "onlyLetters")}
                      onPaste={(e) => validateOnPaste(e, "onlyLetters")}
                      onChange={handleChange}
                      onBlur={(e) =>
                        handleBlurCardHolder(e, setFieldTouched, setFieldValue)
                      }
                      label=""
                      inputRef={cardHolderInput}
                      inputProps={{ maxLength: 62 }} // Por lo momentos se limita a 3 grupos con sus respectivos espacios
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        startAdornment: (
                          <AdornmmentInputs type="user" alt="userIcon" />
                        ),
                      }}
                      error={hasError(errors.cardHolder, touched.cardHolder)}
                      helperText={getHelperTextError(
                        errors.cardHolder,
                        touched.cardHolder
                      )}
                    />
                  </Grid>
                </Box>
                <Grid item xs>
                  <Form.Label className={classes.labelRoot}>
                    Documento
                  </Form.Label>
                  <TextField
                    variant="outlined"
                    id="documentType"
                    name="documentType"
                    value={values.documentType}
                    select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label=""
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <AdornmmentInputs type="typeDoc" alt="typeDoc" />
                      ),
                    }}
                    SelectProps={{ native: true }}
                    error={hasError(errors.documentType, touched.documentType)}
                    helperText={getHelperTextError(
                      errors.documentType,
                      touched.documentType
                    )}
                  >
                    {tiposDocumentos.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs>
                  <Form.Label className={classes.labelRoot}>Numero</Form.Label>
                  <TextField
                    variant="outlined"
                    id="documentNumber"
                    type="text"
                    name="documentNumber"
                    value={values.documentNumber}
                    onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                    onKeyPress={(e) => validateOnKeyDown(e, "onlyNumbers")}
                    onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label=""
                    fullWidth
                    inputProps={{
                      maxLength: isEqual(values.documentType, "DNI") ? 8 : 11,
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      startAdornment: (
                        <AdornmmentInputs type="number" alt="nuberDoc" />
                      ),
                    }}
                    error={hasError(
                      errors.documentNumber,
                      touched.documentNumber
                    )}
                    helperText={getHelperTextError(
                      errors.documentNumber,
                      touched.documentNumber
                    )}
                  />
                </Grid>
                <Grid item xs>
                  <Form.Label className={classes.labelRoot}>
                    Número de tarjeta
                  </Form.Label>
                  <TextField
                    variant="outlined"
                    id="cardNumber"
                    name="cardNumber"
                    type="tel"
                    value={values.cardNumber}
                    onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                    onKeyPress={(e) => validateOnKeyDown(e, "onlyNumbers")}
                    onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                    onChange={(e) =>
                      handleChangeCardNumber(
                        e,
                        handleChange,
                        values.cardSelected
                      )
                    }
                    onBlur={handleBlur}
                    label=""
                    fullWidth
                    disabled={disabledNoCardSelected(values.cardSelected)}
                    inputRef={cardNumberInput}
                    InputProps={{
                      startAdornment: (
                        <AdornmmentInputs type="card" alt="Placeholder card" />
                      ),
                    }}
                    error={hasError(errors.cardNumber, touched.cardNumber)}
                    helperText={getHelperTextError(
                      errors.cardNumber,
                      touched.cardNumber
                    )}
                  />
                </Grid>
                <Grid item xs>
                  <Form.Label className={classes.labelRoot}>
                    Fecha de vencimiento
                  </Form.Label>
                  <SmgDatePicker
                    id="cardExpiryDate"
                    minDate={getMinDate}
                    dateFormat="MM/yy"
                    onChange={(e) => handleChangePicker(e, setFieldValue)}
                    onChangeRaw={(e) => e.preventDefault()}
                    onBlur={handleBlur}
                    selected={values.cardExpiryDate}
                    showMonthYearPicker
                    placeholderText="MM/AA"
                    customInput={
                      <TextField
                        variant="outlined"
                        label=""
                        inputRef={expiryDateInput}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <AdornmmentInputs type="caledar" alt="caledar" />
                          ),
                        }}
                        error={hasError(
                          errors.cardExpiryDate,
                          touched.cardExpiryDate
                        )}
                        helperText={getHelperTextError(
                          errors.cardExpiryDate,
                          touched.cardExpiryDate
                        )}
                      />
                    }
                  />
                </Grid>
                <Grid item xs>
                  <Form.Label className={classes.labelRoot}>CVV</Form.Label>
                  <TextField
                    variant="outlined"
                    id="cardSecurity"
                    name="cardSecurity"
                    value={values.cardSecurity}
                    onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                    onKeyPress={(e) => validateOnKeyDown(e, "onlyNumbers")}
                    onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label=""
                    fullWidth
                    inputProps={{
                      maxLength: !isUndefined(cardType)
                        ? get(cardType, ["codeSecurity", "length"])
                        : get(cardTypes, [0, "codeSecurity", "length"]),
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      startAdornment: <AdornmmentInputs type="cvv" alt="cvv" />,
                    }}
                    error={hasError(errors.cardSecurity, touched.cardSecurity)}
                    helperText={getHelperTextError(
                      errors.cardSecurity,
                      touched.cardSecurity
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <PaymentsInput
                    amountToPayDescription={Number(
                      values.amountToPayDescription
                    )}
                    fullPaymentValue={values.fullPayment}
                    favorValue={favorValue}
                    partialPaymentValue={values.partialPayment}
                    handleChangeRadio={handleChange}
                    handleChangeInput={setFieldValue}
                    handleBlur={handleBlur}
                    hiddenRadios={false}
                    errorGetBalance={false}
                    errorInput={hasError(
                      errors.partialPayment,
                      touched.partialPayment
                    )}
                    helperTextErrorInput={getHelperTextError(
                      errors.partialPayment,
                      touched.partialPayment
                    )}
                  />
                </Grid>
                <Grid item xs>
                  <Form.Label className={classes.labelRoot}>Cuotas</Form.Label>
                  <TextField
                    variant="outlined"
                    id="cuotas"
                    name="cuotas"
                    value={values.cuotas}
                    select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label=""
                    fullWidth
                    SelectProps={{ native: true }}
                    error={hasError(errors.cuotas, touched.cuotas)}
                    helperText={getHelperTextError(
                      errors.cuotas,
                      touched.cuotas
                    )}
                  >
                    {cantCuotas.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    className={classes.labelCheck}
                    label="¿Querés recibir el comprobante de pago por email?"
                    control={
                      <Checkbox
                        color="default"
                        className={classes.check}
                        checked={values.checked}
                        onChange={handleChange}
                        name="checked"
                      />
                    }
                  />
                  <TextField
                    variant="outlined"
                    id="email"
                    name="email"
                    type="text"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={hasError(errors.email, touched.email)}
                    helperText={getHelperTextError(errors.email, touched.email)}
                  />
                </Grid>

                <ReCAPTCHA
                    ref={recaptchaRef}
                    onChange={handleRecaptchaChange}
                    OnExpired={handleOnExpired}
                    style={{ marginTop: "5%" }}
                    size="normal"
                    sitekey={SITE_KEY}
                  />

                <Grid
                  container
                  xs
                  style={{ justifyContent: "center", marginTop: "5%" }}
                  spacing={3}
                >
                  <Grid item xs>
                    <Button
                      style={{ color: "#fffff", width: "306px" }}
                      className={classes.ButtonP}
                      disabled={!tokenRecaptcha || Object.keys(errors).length !== 0}
                      type="submit"
                    >
                      Pagar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </MediaQuery>
            <MediaQuery minDeviceWidth={768} and maxDeviceWidth={991}>
              <Grid
                component="form"
                onSubmit={handleSubmit}
                autoComplete="off"
                className={classes.root}
              >
                <Grid container directionalignItems="flex-start" spacing={2}>
                  <Grid item md={12}>
                    <SelectorCard
                      selectedValue={values.cardSelected}
                      handleChange={(e) =>
                        handleChangeCardType(e, handleChange)
                      }
                      name="cardSelected"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Form.Label className={classes.labelRoot}>
                      Nombre en la tarjeta
                    </Form.Label>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="cardHolder"
                      name="cardHolder"
                      value={values.cardHolder}
                      onKeyDown={(e) => validateOnKeyDown(e, "onlyLetters")}
                      onKeyPress={(e) => validateOnKeyDown(e, "onlyLetters")}
                      onPaste={(e) => validateOnPaste(e, "onlyLetters")}
                      onChange={handleChange}
                      onBlur={(e) =>
                        handleBlurCardHolder(e, setFieldTouched, setFieldValue)
                      }
                      label=""
                      inputRef={cardHolderInput}
                      inputProps={{ maxLength: 62 }} // Por lo momentos se limita a 3 grupos con sus respectivos espacios
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        startAdornment: (
                          <AdornmmentInputs type="user" alt="userIcon" />
                        ),
                      }}
                      error={hasError(errors.cardHolder, touched.cardHolder)}
                      helperText={getHelperTextError(
                        errors.cardHolder,
                        touched.cardHolder
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Label className={classes.labelRoot}>
                      Documento
                    </Form.Label>
                    <TextField
                      variant="outlined"
                      id="documentType"
                      name="documentType"
                      value={values.documentType}
                      select
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label=""
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <AdornmmentInputs type="typeDoc" alt="typeDoc" />
                        ),
                      }}
                      SelectProps={{ native: true }}
                      error={hasError(
                        errors.documentType,
                        touched.documentType
                      )}
                      helperText={getHelperTextError(
                        errors.documentType,
                        touched.documentType
                      )}
                    >
                      {tiposDocumentos.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Label className={classes.labelRoot}>
                      Numero
                    </Form.Label>
                    <TextField
                      variant="outlined"
                      id="documentNumber"
                      type="text"
                      name="documentNumber"
                      value={values.documentNumber}
                      onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                      onKeyPress={(e) => validateOnKeyDown(e, "onlyNumbers")}
                      onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label=""
                      fullWidth
                      inputProps={{
                        maxLength: isEqual(values.documentType, "DNI") ? 8 : 11,
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        startAdornment: (
                          <AdornmmentInputs type="number" alt="nuberDoc" />
                        ),
                      }}
                      error={hasError(
                        errors.documentNumber,
                        touched.documentNumber
                      )}
                      helperText={getHelperTextError(
                        errors.documentNumber,
                        touched.documentNumber
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Form.Label className={classes.labelRoot}>
                      Número de tarjeta
                    </Form.Label>
                    <TextField
                      variant="outlined"
                      id="cardNumber"
                      name="cardNumber"
                      type="tel"
                      value={values.cardNumber}
                      onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                      onKeyPress={(e) => validateOnKeyDown(e, "onlyNumbers")}
                      onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                      onChange={(e) =>
                        handleChangeCardNumber(
                          e,
                          handleChange,
                          values.cardSelected
                        )
                      }
                      onBlur={handleBlur}
                      label=""
                      fullWidth
                      disabled={disabledNoCardSelected(values.cardSelected)}
                      inputRef={cardNumberInput}
                      InputProps={{
                        startAdornment: (
                          <AdornmmentInputs
                            type="card"
                            alt="Placeholder card"
                          />
                        ),
                      }}
                      error={hasError(errors.cardNumber, touched.cardNumber)}
                      helperText={getHelperTextError(
                        errors.cardNumber,
                        touched.cardNumber
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Label className={classes.labelRoot}>
                      Fecha de vencimiento
                    </Form.Label>
                    <SmgDatePicker
                      id="cardExpiryDate"
                      minDate={getMinDate}
                      dateFormat="MM/yy"
                      onChange={(e) => handleChangePicker(e, setFieldValue)}
                      onChangeRaw={(e) => e.preventDefault()}
                      onBlur={handleBlur}
                      selected={values.cardExpiryDate}
                      showMonthYearPicker
                      placeholderText="MM/AA"
                      customInput={
                        <TextField
                          variant="outlined"
                          label=""
                          inputRef={expiryDateInput}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <AdornmmentInputs type="caledar" alt="caledar" />
                            ),
                          }}
                          error={hasError(
                            errors.cardExpiryDate,
                            touched.cardExpiryDate
                          )}
                          helperText={getHelperTextError(
                            errors.cardExpiryDate,
                            touched.cardExpiryDate
                          )}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Label className={classes.labelRoot}>CVV</Form.Label>
                    <TextField
                      variant="outlined"
                      id="cardSecurity"
                      name="cardSecurity"
                      value={values.cardSecurity}
                      onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                      onKeyPress={(e) => validateOnKeyDown(e, "onlyNumbers")}
                      onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label=""
                      fullWidth
                      inputProps={{
                        maxLength: !isUndefined(cardType)
                          ? get(cardType, ["codeSecurity", "length"])
                          : get(cardTypes, [0, "codeSecurity", "length"]),
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        startAdornment: (
                          <AdornmmentInputs type="cvv" alt="cvv" />
                        ),
                      }}
                      error={hasError(
                        errors.cardSecurity,
                        touched.cardSecurity
                      )}
                      helperText={getHelperTextError(
                        errors.cardSecurity,
                        touched.cardSecurity
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <PaymentsInput
                      amountToPayDescription={Number(
                        values.amountToPayDescription
                      )}
                      fullPaymentValue={values.fullPayment}
                      favorValue={favorValue}
                      partialPaymentValue={values.partialPayment}
                      handleChangeRadio={handleChange}
                      handleChangeInput={setFieldValue}
                      handleBlur={handleBlur}
                      hiddenRadios={false}
                      errorGetBalance={false}
                      errorInput={hasError(
                        errors.partialPayment,
                        touched.partialPayment
                      )}
                      helperTextErrorInput={getHelperTextError(
                        errors.partialPayment,
                        touched.partialPayment
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Label className={classes.labelRoot}>
                      Cuotas
                    </Form.Label>
                    <TextField
                      variant="outlined"
                      id="cuotas"
                      name="cuotas"
                      value={values.cuotas}
                      select
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label=""
                      fullWidth
                      SelectProps={{ native: true }}
                      error={hasError(errors.cuotas, touched.cuotas)}
                      helperText={getHelperTextError(
                        errors.cuotas,
                        touched.cuotas
                      )}
                    >
                      {cantCuotas.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControlLabel
                      className={classes.labelCheck}
                      control={
                        <Checkbox
                          color="default"
                          className={classes.check}
                          checked={values.checked}
                          onChange={handleChange}
                          name="checked"
                        />
                      }
                      label="¿Querés recibir el comprobante de pago por email?"
                    />
                    <TextField
                      variant="outlined"
                      id="email"
                      name="email"
                      type="text"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={hasError(errors.email, touched.email)}
                      helperText={getHelperTextError(
                        errors.email,
                        touched.email
                      )}
                    />
                  </Grid>

                  <ReCAPTCHA
                    ref={recaptchaRef}
                    onChange={handleRecaptchaChange}
                    OnExpired={handleOnExpired}
                    style={{ marginTop: "5%" }}
                    size="normal"
                    sitekey={SITE_KEY}
                  />

                  <Grid
                    container
                    xs={12}
                    style={{ justifyContent: "center", marginTop: "5%" }}
                    spacing={3}
                  >
                    <Grid item>
                      <Button
                        style={{ color: "#fffff" }}
                        className={classes.ButtonP}
                        disabled={!tokenRecaptcha || Object.keys(errors).length !== 0}
                        type="submit"
                      >
                        Pagar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MediaQuery>
            <MediaQuery minDeviceWidth={992}>
              <Grid
                component="form"
                onSubmit={handleSubmit}
                autoComplete="off"
                className={classes.root}
              >
                <Grid container directionalignItems="flex-start" spacing={2}>
                  <Grid item md={12}>
                    <SelectorCard
                      selectedValue={values.cardSelected}
                      handleChange={(e) =>
                        handleChangeCardType(e, handleChange)
                      }
                      name="cardSelected"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Form.Label className={classes.labelRoot}>
                      Nombre en la tarjeta
                    </Form.Label>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="cardHolder"
                      name="cardHolder"
                      value={values.cardHolder}
                      onKeyDown={(e) => validateOnKeyDown(e, "onlyLetters")}
                      onKeyPress={(e) => validateOnKeyDown(e, "onlyLetters")}
                      onPaste={(e) => validateOnPaste(e, "onlyLetters")}
                      onChange={handleChange}
                      onBlur={(e) =>
                        handleBlurCardHolder(e, setFieldTouched, setFieldValue)
                      }
                      label=""
                      inputRef={cardHolderInput}
                      inputProps={{ maxLength: 62 }} // Por lo momentos se limita a 3 grupos con sus respectivos espacios
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        startAdornment: (
                          <AdornmmentInputs type="user" alt="userIcon" />
                        ),
                      }}
                      error={hasError(errors.cardHolder, touched.cardHolder)}
                      helperText={getHelperTextError(
                        errors.cardHolder,
                        touched.cardHolder
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Label className={classes.labelRoot}>
                      Documento
                    </Form.Label>
                    <TextField
                      variant="outlined"
                      id="documentType"
                      name="documentType"
                      value={values.documentType}
                      select
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label=""
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <AdornmmentInputs type="typeDoc" alt="typeDoc" />
                        ),
                      }}
                      SelectProps={{ native: true }}
                      error={hasError(
                        errors.documentType,
                        touched.documentType
                      )}
                      helperText={getHelperTextError(
                        errors.documentType,
                        touched.documentType
                      )}
                    >
                      {tiposDocumentos.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Label className={classes.labelRoot}>
                      Numero
                    </Form.Label>
                    <TextField
                      variant="outlined"
                      id="documentNumber"
                      type="text"
                      name="documentNumber"
                      value={values.documentNumber}
                      onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                      onKeyPress={(e) => validateOnKeyDown(e, "onlyNumbers")}
                      onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label=""
                      fullWidth
                      inputProps={{
                        maxLength: isEqual(values.documentType, "DNI") ? 8 : 11,
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        startAdornment: (
                          <AdornmmentInputs type="number" alt="nuberDoc" />
                        ),
                      }}
                      error={hasError(
                        errors.documentNumber,
                        touched.documentNumber
                      )}
                      helperText={getHelperTextError(
                        errors.documentNumber,
                        touched.documentNumber
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Form.Label className={classes.labelRoot}>
                      Número de tarjeta
                    </Form.Label>
                    <TextField
                      variant="outlined"
                      id="cardNumber"
                      name="cardNumber"
                      type="tel"
                      value={values.cardNumber}
                      onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                      onKeyPress={(e) => validateOnKeyDown(e, "onlyNumbers")}
                      onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                      onChange={(e) =>
                        handleChangeCardNumber(
                          e,
                          handleChange,
                          values.cardSelected
                        )
                      }
                      onBlur={handleBlur}
                      label=""
                      fullWidth
                      disabled={disabledNoCardSelected(values.cardSelected)}
                      inputRef={cardNumberInput}
                      InputProps={{
                        startAdornment: (
                          <AdornmmentInputs
                            type="card"
                            alt="Placeholder card"
                          />
                        ),
                      }}
                      error={hasError(errors.cardNumber, touched.cardNumber)}
                      helperText={getHelperTextError(
                        errors.cardNumber,
                        touched.cardNumber
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Label className={classes.labelRoot}>
                      Fecha de vencimiento
                    </Form.Label>
                    <SmgDatePicker
                      id="cardExpiryDate"
                      minDate={getMinDate}
                      dateFormat="MM/yy"
                      onChange={(e) => handleChangePicker(e, setFieldValue)}
                      onChangeRaw={(e) => e.preventDefault()}
                      onBlur={handleBlur}
                      selected={values.cardExpiryDate}
                      showMonthYearPicker
                      placeholderText="MM/AA"
                      customInput={
                        <TextField
                          variant="outlined"
                          label=""
                          inputRef={expiryDateInput}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <AdornmmentInputs type="caledar" alt="caledar" />
                            ),
                          }}
                          error={hasError(
                            errors.cardExpiryDate,
                            touched.cardExpiryDate
                          )}
                          helperText={getHelperTextError(
                            errors.cardExpiryDate,
                            touched.cardExpiryDate
                          )}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Label className={classes.labelRoot}>CVV</Form.Label>
                    <TextField
                      variant="outlined"
                      id="cardSecurity"
                      name="cardSecurity"
                      value={values.cardSecurity}
                      onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                      onKeyPress={(e) => validateOnKeyDown(e, "onlyNumbers")}
                      onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label=""
                      fullWidth
                      inputProps={{
                        maxLength: !isUndefined(cardType)
                          ? get(cardType, ["codeSecurity", "length"])
                          : get(cardTypes, [0, "codeSecurity", "length"]),
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        startAdornment: (
                          <AdornmmentInputs type="cvv" alt="cvv" />
                        ),
                      }}
                      error={hasError(
                        errors.cardSecurity,
                        touched.cardSecurity
                      )}
                      helperText={getHelperTextError(
                        errors.cardSecurity,
                        touched.cardSecurity
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <PaymentsInput
                      amountToPayDescription={Number(
                        values.amountToPayDescription
                      )}
                      fullPaymentValue={values.fullPayment}
                      favorValue={favorValue}
                      partialPaymentValue={values.partialPayment}
                      handleChangeRadio={handleChange}
                      handleChangeInput={setFieldValue}
                      handleBlur={handleBlur}
                      hiddenRadios={false}
                      errorGetBalance={false}
                      errorInput={hasError(
                        errors.partialPayment,
                        touched.partialPayment
                      )}
                      helperTextErrorInput={getHelperTextError(
                        errors.partialPayment,
                        touched.partialPayment
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Label className={classes.labelRoot}>
                      Cuotas
                    </Form.Label>
                    <TextField
                      variant="outlined"
                      id="cuotas"
                      name="cuotas"
                      value={values.cuotas}
                      select
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label=""
                      fullWidth
                      SelectProps={{ native: true }}
                      error={hasError(errors.cuotas, touched.cuotas)}
                      helperText={getHelperTextError(
                        errors.cuotas,
                        touched.cuotas
                      )}
                    >
                      {cantCuotas.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControlLabel
                      className={classes.labelCheck}
                      control={
                        <Checkbox
                          color="default"
                          className={classes.check}
                          checked={values.checked}
                          onChange={handleChange}
                          name="checked"
                        />
                      }
                      label="¿Querés recibir el comprobante de pago por email?"
                    />
                    <TextField
                      variant="outlined"
                      id="email"
                      name="email"
                      type="text"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={hasError(errors.email, touched.email)}
                      helperText={getHelperTextError(
                        errors.email,
                        touched.email
                      )}
                    />
                  </Grid>

                  <ReCAPTCHA
                    ref={recaptchaRef}
                    onChange={handleRecaptchaChange}
                    onExpired={handleOnExpired}
                    style={{ marginTop: "5%" }}
                    size="normal"
                    sitekey={SITE_KEY}
                  />

                  <Grid
                    container
                    xs={12}
                    style={{ justifyContent: "center", marginTop: "5%" }}
                    spacing={3}
                  >
                    <Grid item>
                      <Button
                        style={{ color: "#fffff" }}
                        className={classes.ButtonP}
                        type="submit"
                        disabled={!tokenRecaptcha || Object.keys(errors).length !== 0}
                      >
                        Pagar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MediaQuery>
          </div>
        )}
      </Formik>
    </div>
  );
}

PagosForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(useStyles)(PagosForm);
