import React from 'react';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { BsBank } from 'react-icons/bs';
import { BiCreditCard } from 'react-icons/bi';

const paymentIcons = [
    <BiCreditCard />,
    <FaRegMoneyBillAlt />,
    <BsBank />,
];

export const FormasDePagoComponent = ({ data }) => {

    return (
        <>
            <h1 className="parrafo" style={{marginTop: '83px'}}>
                { data.formasDePago.titulo }
            </h1>
            
            <p className="margen-descripcion">
                { data.formasDePago.descripcion }
            </p>

            <div style={{ textAlign: 'left', paddingLeft: '18px' }}>

            <ul className="list-group list-group-flush margen itemList">
                {data.formasDePago.opcionesDePago.map((opcion, index) => (
                    <li className="align-items-end" key={index}>
                        <span style={{ verticalAlign: 'middle', marginRight: '8px' }}>
                            {paymentIcons[index]}
                        </span> 
                        {opcion}
                    </li>
                ))}
            </ul>

        </div>    
        </>
    )
}