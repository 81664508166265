import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { Grid, Typography } from "@material-ui/core";
import {
  InputField,
  DatePickerField,
  SelectField,
  RadioField,
} from "../FormFields";
import Divider from "@material-ui/core/Divider";

// import NiceInputPassword from "react-nice-input-password";
// import { TextField, InputLabel } from "@material-ui/core";
// import LockIcon from "@material-ui/icons/Lock";

import InputPasswordField from "../FormFields/InputPasswordField/index";

// If you wanna use the basic css from this library (Optional)
import "react-nice-input-password/dist/react-nice-input-password.css";

import "../registrarse.css";

const type = [
  {
    value: "DU",
    label: "DNI",
  },
  {
    value: "PA",
    label: "PASAPORTE",
  },
];

const handleKeyPress = (event) => {
  const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/;
  if (!regex.test(event.key)) {
    event.preventDefault();                     
  }
};

const handleKeyPressDocument = (event, tipo) => {
  let regex = /^[0-9]*$/; // evitar undefined

  if (tipo === "PA") {
    regex = /^[a-zA-Z0-9]*$/; 
  }

  if (event.key === "Backspace" || event.key === "Delete" || event.key.startsWith("Arrow")) {
    return;
  }

  if (!regex.test(event.key)) {
    event.preventDefault();                     
  }
};


/* const levelBarCss = (level) => ({
  height: "8px",
  width: level > 0 ? `${(100 / 4) * level}%` : "100%",
  marginTop: 16,
  transition: "width 0.5s ease",
  backgroundColor: ["#EFEFEF", "red", "orange", "yellow", "green"][level],
  borderRadius: 0,
});

const CustomLevelBar = (levels) => <div style={levelBarCss(levels)} />; */

export default function DatosPersonales(props) {
  const {
    formField: {
      nombre,
      apellido,
      tipo,
      documento,
      genero,
      fecha,
      email,
      confirmEmail,
      clave,
      terms,
    },
  } = props;

  const { values, setFieldValue, handleChange } = useFormikContext();

  useEffect(() => {
   // setFieldValue(documento.name, ""); 
   setFieldValue(documento.name, String(values.documento || ""));
  }, [values.tipo]); 

 
  // const securityLevels = [
  //   {
  //     descriptionLabel: <Typography>1 number</Typography>,
  //     validator: /.*[0-9].*/,
  //   },
  //   {
  //     descriptionLabel: <Typography>1 lowercase letter</Typography>,
  //     validator: /.*[a-z].*/,
  //   },
  //   {
  //     descriptionLabel: <Typography>1 uppercase letter</Typography>,
  //     validator: /.*[A-Z].*/,
  //   },
  //   {
  //     descriptionLabel: <Typography>6 of length</Typography>,
  //     validator: /^.{6,}$/,
  //   },
  // ];

  // const handleChange = (data) => {
  //   props.formField.clave = data;
  // };

  return (
    <React.Fragment>
      <Typography className="datos-personales" gutterBottom>
        REGISTRACIÓN
      </Typography>
      <Typography gutterBottom>
        La registración del servicio deberá ser realizada únicamente por el
        titular del contrato.
      </Typography>
      <Typography className="datos-personales">Datos personales</Typography>

      <Divider variant="middle" className="divider" />

      <div style={{ margin: 20 }}>
        <Grid container spacing={0} justify="center">
          <Grid item xs={7}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <InputField 
                  name={nombre.name} 
                  label={nombre.label} 
                  fullWidth 
                  onKeyDown={handleKeyPress}/>
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name={apellido.name}
                  label={apellido.label}
                  fullWidth
                  onKeyDown={handleKeyPress}
                />
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                  <SelectField
                    name={tipo.name}
                    label={tipo.label}
                    data={type}
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <InputField
                    name={documento.name}
                    label={documento.label}
                    fullWidth
                    value={values.documento}  
                    onKeyDown={(e) => handleKeyPressDocument(e, values.tipo)}  
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <RadioField name={genero.name} label={genero.label} />
              </Grid>
              <Grid item xs={5}>
                <DatePickerField
                  name={fecha.name}
                  label={fecha.label}
                  format="DD/MM/YYYY"
                  views={["day", "month", "year"]}
                  minDate={new Date()}
                  maxDate={new Date("31/12/2050")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <InputField name={email.name} label={email.label} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name={confirmEmail.name}
                  label={confirmEmail.label}
                  fullWidth
                />{" "}
              </Grid>
              <Grid item xs={12}>
                <InputPasswordField
                  name={clave.name}
                  label={clave.label}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Divider className="divider-end" />
    </React.Fragment>
  );
}
