import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import {
  CssBaseline,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  InputLabel,
  IconButton,
  MenuItem,
  TextField,
  InputAdornment,
  Grid,
  Link,
} from "@material-ui/core";
import MediaQuery from "react-responsive";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import Navbar from "../Navbar/Navbar";
import { isEqual } from "lodash";
import * as yup from "yup";
import Modal from "../../utils/Modal";
import Loading from "../../utils/Loading";
import { getBackendToken as auth } from "../../utils/backendRequest";
import ReCAPTCHA from "react-google-recaptcha";
import "../Ingresar/ingresar.css";
import { getByTestId } from "@testing-library/react";
import { authLoginActions } from "../../actions/authLoginActions";
import {errorAlert, messageAlert} from "../../utils/alerts/alerts";
import {ENVIRONMENT_URL, SITE_KEY} from "../../constants";
import { setGlobal } from "../../config/global";

const validationSchema = yup.object({
  tDocumento: yup.string().required("Campo requerido"),
  nDocumento: yup.string().required("Campo requerido"),
  password: yup.string().required("Campo requerido"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    "& .MuiFormLabel-root": {
      fontSize: "18px",
      fontFamily: 'Red Hat Display',
    },
    "& .MuiFormHelperText-root": {
      fontSize: "13px",
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
    },
    "& .MuiTypography-body1": {
      fontSize: "18px",
      fontFamily: 'Red Hat Display',
      color: "#A3A2A2",
      fontWeight: "bold",
      "@media (min-width:768px)": {
        fontSize: "13px",
        fontFamily: 'Red Hat Display',
        color: "#A3A2A2",

        fontWeight: "bold",
      },
    },
    "& .MuiTypography-body2": {
      fontSize: "18px",
      "@media (min-width:768px)": {
        fontSize: "13px",
        fontFamily: 'Red Hat Display',
      },
    },
    "& .MuiSvgIcon-root": {
      color: "#00758d",
    },
  },
  paper: {
    margin: theme.spacing(12, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paperB: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Ingresar(props) {
  const recaptchaRef = React.createRef();
  const classes = useStyles();

  const [values, setValues] = React.useState({
    showPassword: false,
    show: false,
    isOpen: false,
    isOpenLoading: false,
    messageModal: "",
    cardType: undefined,
    filterErrors: {},
  });

  const [modal, setModal] = useState({
    open: false,
    type: 500,
    title: "",
  });

  useEffect(() => {
    const outButton = () => {
      //const btn = document.getElementById("sirena_button")
      //btn.style.display="none"
    };
    window.addEventListener("load", outButton);
    return () => window.removeEventListener("load", outButton);
  }, []);

  const handleRegistracion = () => {
    window.location.href = "/registrar-nuevo-clientes";
  };

  const handleRecover = () => {
    messageAlert('info', 'Información','Para restablecer su clave comuniquese vía mail a smgcells@swissmedical.com.ar');
  };

  //Loading
  const handleOpenLoading = () => {
    setValues({ ...values, isOpenLoading: true });
  };

  const handleCloseLoading = () => {
    setValues({ ...values, isOpenLoading: false });
  };

  const handleClickShowPassword = () => {
    setValues({ ...formik, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateOnKeyDown = (evt, type = "onlyNumbers") => {
    const regexs = {
      onlyNumbers: /[0-9]/,
      onlyLetters: /[a-zA-ZñÑ]/,
    };

    const { keyCode, which, ctrlKey, key } = evt;
    const charCode = which || keyCode;

    const pressBackSpace = isEqual(charCode, 8);
    const pressTab = isEqual(charCode, 9);
    const pressSpace = isEqual(charCode, 32);
    const pressCtrlAndV = ctrlKey ? ctrlKey && isEqual(charCode, 86) : false;
    const pressKeyInlcudeInRegex = regexs[type].test(key);

    if (
      !pressBackSpace &&
      !pressTab &&
      (isEqual(type, "onlyLetters") ? !pressSpace : true) &&
      !pressCtrlAndV &&
      !pressKeyInlcudeInRegex
    ) {
      return evt.preventDefault();
    }
  };

  // eslint-disable-next-line consistent-return
  const validateOnPaste = (evt, type = "onlyNumbers") => {
    const regexs = {
      onlyNumbers: /^[0-9]+$/,
      onlyLetters: /^[a-zA-ZñÑ]{1,20}(?:\s[a-zA-ZñÑ]{1,20}){0,9}$/,
    };
    const clipBoard = evt.clipboardData.getData("Text");

    if (!regexs[type].test(clipBoard)) {
      return evt.preventDefault();
    }
  };

  const onSubmitWithReCAPTCHA = async (values) => {
    const tokenRc = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    if (tokenRc) {
      const data = {
        recaptchaResponse: tokenRc,
        ...values,
      };
      getTokenBackend(data);
    } else {
      errorAlert(
        "Tuvimos un problema procesando tu solicitud",
        "Volve a intentarlo en unos minutos por favor."
      );
    }
  };

  //Calls backend

  //const url = `${process.env.REACT_APP_BACKEND}/api/auth-login`;
  const url = `${ENVIRONMENT_URL}/app/api/auth-login`;
  const getTokenBackend = (values) => {
    formik.setSubmitting(true);

    auth( url, values, () => {
        props.history.push("/contratos");
    }, (err) => {
        formik.setErrors({ password: "Datos incorrectos", });   
        formik.setSubmitting(false);   
    });
  };   

  const formik = useFormik({
    initialValues: {
      tDocumento: "",
      nDocumento: "",
      password: "",
      showPassword: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await onSubmitWithReCAPTCHA(values);
    },
  });

  setGlobal({ tipoDocumento: formik.values.tDocumento, numDocumento: formik.values.nDocumento});

  return (
    <div>
      <Modal
        open={modal.open}
        maxWidth="lg"
        closeIconButton
        title={modal.title}
        onClose={() => setModal(false)}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      ></Modal>

      <Loading
        isOpenLoading={values.isOpenLoading}
        handleCloseLoading={handleCloseLoading}
      ></Loading>
      <MediaQuery maxDeviceWidth={576}>
        <Navbar />
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className="image-b" />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <div className={classes.paper}>
              <form
                className="form-fondo"
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="standard-adornment-password">
                      <strong className="b-1">Tipo de documento</strong>
                    </InputLabel>
                    <TextField
                      select
                      fullWidth
                      margin="normal"
                      id="tDocumento"
                      name="tDocumento"
                      value={formik.values.tDocumento}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.tDocumento &&
                        Boolean(formik.errors.tDocumento)
                      }
                      helperText={
                        formik.touched.tDocumento && formik.errors.tDocumento
                      }
                    >
                      <MenuItem value="">
                        <em>Seleccionar</em>
                      </MenuItem>
                      <MenuItem value={"DU"}>DU</MenuItem>
                      <MenuItem value={"Pasaporte"}>Pasaporte</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="standard-adornment-password">
                      <strong className="b-1">Número de documento</strong>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="nDocumento"
                      name="nDocumento"
                      label=""
                      value={formik.values.nDocumento}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.nDocumento &&
                        Boolean(formik.errors.nDocumento)
                      }
                      helperText={
                        formik.touched.nDocumento && formik.errors.nDocumento
                      }
                      onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                      onKeyPress={(e) => validateOnKeyDown(e, "onlyNumbers")}
                      onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="standard-adornment-password">
                      <strong className="b-1">Contraseña</strong>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type={values.showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      label=""
                      value={formik.values.password}
                      userPassword={formik.values.password}
                      onChange={formik.handleChange}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </Grid>
                </Grid>
                <br></br>
                <br></br>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Recordar mi número de documento"
                />
                <br></br>
                <br></br>
                <Grid container>
                  <Grid item xs className={classes.paperB}>
                    <button
                      type="submit"
                      className="btn btn-primary btn-ingresar"
                    >
                      INGRESAR
                    </button>
                  </Grid>
                </Grid>
              </form>
              <div>
              <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    handleRecover();
                  }}>
                ¿Olvidaste la contraseña?
              </Link>

              </div>
              <div className="div-no-registrado">
                <NavLink
                  exact
                  to="/registrar-nuevo-clientes"
                  className="text-no-registrado"
                >
                  NO ESTOY REGISTRADO
                </NavLink>
              </div>
              <ReCAPTCHA
                ref={recaptchaRef}
                style={{ marginTop: "5%" }}
                size="invisible"
                sitekey={SITE_KEY}
              />
            </div>
          </Grid>
        </Grid>
      </MediaQuery>
      <MediaQuery minDeviceWidth={768} and maxDeviceWidth={991}>
        {/* Table devices - Portrait */}

        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={6} md={7} className="image-b" />

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            component={Paper}
            elevation={6}
            square
          >
            <Box display="flex" justifyContent="center" bgcolor="#ffff">
              <Box alignSelf="flex-end">
                <div className={classes.paper}>
                  <div style={{display: "flex", justifyContent: "center"}} className="brand-wrapper">
                    <img style={{"width": "45%"}} src="img/Swiss_Medical_CELLS_Logo.png" alt="new_logo"/>
                  </div>
                  <form
                      className="form-fondo"
                      autoComplete="off"
                      onSubmit={formik.handleSubmit}
                  >
                  <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1">Tipo de documento</strong>
                        </InputLabel>
                        <TextField
                          select
                          fullWidth
                          margin="normal"
                          id="tDocumento"
                          name="tDocumento"
                          value={formik.values.tDocumento}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.tDocumento &&
                            Boolean(formik.errors.tDocumento)
                          }
                          helperText={
                            formik.touched.tDocumento &&
                            formik.errors.tDocumento
                          }
                        >
                          <MenuItem value="">
                            <em>Seleccionar</em>
                          </MenuItem>
                          <MenuItem value={"DU"}>DU</MenuItem>
                          <MenuItem value={"Pasaporte"}>Pasaporte</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1">Número de documento</strong>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="nDocumento"
                          name="nDocumento"
                          label=""
                          value={formik.values.nDocumento}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.nDocumento &&
                            Boolean(formik.errors.nDocumento)
                          }
                          helperText={
                            formik.touched.nDocumento &&
                            formik.errors.nDocumento
                          }
                          onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                          onKeyPress={(e) =>
                            validateOnKeyDown(e, "onlyNumbers")
                          }
                          onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1">Contraseña</strong>
                        </InputLabel>
                        <TextField
                          fullWidth
                          type={values.showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          label=""
                          value={formik.values.password}
                          userPassword={formik.values.password}
                          onChange={formik.handleChange}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                        />
                      </Grid>
                    </Grid>
                    <br></br>
                    <br></br>
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Recordar mi número de documento"
                    />

                    <Grid container>
                      <Grid item xs className={classes.paperB}>
                        <br />
                        <button
                          type="submit"
                          className="btn btn-primary btn-ii"
                        >
                          INGRESAR
                        </button>
                      </Grid>
                    </Grid>
                  </form>
                  <div className="form-fondo-i">
                    <Grid container>
                      <Grid item xs className={classes.paperB}>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => {
                            handleRecover();
                          }}
                        >
                          ¿Olvidaste la contraseña?
                        </Link>

                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => {
                            handleRegistracion();
                          }}
                        >
                          NO ESTOY REGISTRADO
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Box>
              <ReCAPTCHA
                ref={recaptchaRef}
                style={{ marginTop: "5%" }}
                size="invisible"
                sitekey={SITE_KEY}
              />
            </Box>
          </Grid>
        </Grid>
      </MediaQuery>

      <MediaQuery minDeviceWidth={992}>
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className="image-b" />

          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box display="flex" justifyContent="center" bgcolor="#ffff">
              <Box alignSelf="flex-end">
                <div className={classes.paper}>
                  <div style={{display: "flex", justifyContent: "center"}} className="brand-wrapper">
                    <img style={{"width": "45%"}} src="img/Swiss_Medical_CELLS_Logo.png" alt="new_logo"/>
                  </div>
                  <form
                      className="form-fondo"
                      autoComplete="off"
                      onSubmit={formik.handleSubmit}
                  >
                  <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1">Tipo de documento</strong>
                        </InputLabel>
                        <TextField
                          select
                          fullWidth
                          margin="normal"
                          id="tDocumento"
                          name="tDocumento"
                          value={formik.values.tDocumento}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.tDocumento &&
                            Boolean(formik.errors.tDocumento)
                          }
                          helperText={
                            formik.touched.tDocumento &&
                            formik.errors.tDocumento
                          }
                        >
                          <MenuItem value="">
                            <em>Seleccionar</em>
                          </MenuItem>
                          <MenuItem value={"DU"}>DU</MenuItem>
                          <MenuItem value={"Pasaporte"}>Pasaporte</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1">Número de documento</strong>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="nDocumento"
                          name="nDocumento"
                          label=""
                          value={formik.values.nDocumento}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.nDocumento &&
                            Boolean(formik.errors.nDocumento)
                          }
                          helperText={
                            formik.touched.nDocumento &&
                            formik.errors.nDocumento
                          }
                          onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                          onKeyPress={(e) =>
                            validateOnKeyDown(e, "onlyNumbers")
                          }
                          onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1">Contraseña</strong>
                        </InputLabel>
                        <TextField
                          fullWidth
                          type={values.showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          label=""
                          value={formik.values.password}
                          userPassword={formik.values.password}
                          onChange={formik.handleChange}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                        />
                      </Grid>
                    </Grid>
                    <br></br>
                    <br></br>
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Recordar mi número de documento"
                    />

                    <Grid container>
                      <Grid item xs className={classes.paperB}>
                        <br />
                        <button
                          type="submit"
                          className="btn btn-primary btn-ii"
                        >
                          INGRESAR
                        </button>
                      </Grid>
                    </Grid>
                  </form>

                  <div className="form-fondo-i">
                    <Grid container>
                      <Grid item xs className={classes.paperB}>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => {
                            handleRecover();
                          }}
                        >
                          ¿Olvidaste la contraseña?
                        </Link>

                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => {
                            handleRegistracion();
                          }}
                        >
                          NO ESTOY REGISTRADO
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Box>
              <ReCAPTCHA
                ref={recaptchaRef}
                style={{ marginTop: "5%" }}
                size="invisible"
                sitekey={SITE_KEY}
              />
            </Box>
          </Grid>
        </Grid>
      </MediaQuery>
    </div>
  );
}