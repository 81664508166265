import { Box, Grid, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink, useHistory } from "react-router-dom";
import NavbarButtons from "./NavbarButtons";
import NavbarLogo from "./NavbarLogo";
import { AppBar } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { removeInstance } from "../../../reducers/userInstanceReducer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "&": {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "inline-flex",
      width: "100%",
      justifyContent: "center",
    },
    "& .MuiTab-root": {
      minWidth: "110px",
      opacity: 1,
    },
    "& .MuiAppBar-root": {
      backgroundColor: "#ecf4f5 !important",
      position: "inherit",
      fontSize: "1.5em !important",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2.5rem",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#ecf4f5",
    },
    "& .MuiIconButton-root": {
      fontSize: "9px",
    },
    "& .MuiButton-root": {
      fontFamily: 'Red Hat Display',
      size: "40px",
      fontSize: "14px",
      marginLeft: "38px",
    },
    "& .MuiBox-root": {
      display: "inline-flex",
      alignItems: "center",
      width: "100%",
    },
    "& .MuiBox-root button": {
      lineHeight: "15px",
    },
    "& .navMenu": {
      alignItems: "center",
      justifyContent: "end",
      width: "70%",
    },
    "& .navMenu button": {
      lineHeight: "15px",
      height: "45px",
      fontSize: "1rem",
      marginLeft: "5px",
      [theme.breakpoints.up("lg")]: {
        fontSize: "1.3rem",
      },
    },
    "& .navLogo": {
      width: "30%",
      marginRight: "30px",
    },
    "& .userName": {
      color: "#00748b",
      fontWeight: "bold",
      display: "block",
      fontSize: "1rem",
      textAlign: "center"
    },
    backgroundColor: "#ecf4f5",
    boxShadow: "none",
    color: "#00748b",
  },
  menuButton: {
    fontFamily: 'Red Hat Display',
    size: "40px",
    fontSize: "14px",
    background: "#00748b 0% 0% no-repeat padding-box",
    border: "1px solid #00748b",
    borderRadius: "20px",
    opacity: "1",
    color: "#ffffff",
    width: "110px !important",
    textTransform: "capitalize",
  
    height: "40px",
    "&:hover": {
      color: "#ffffff",
    },
    "@media (min-width: 768px) and (max-width: 991px)": {
      fontSize: "10px",
      marginLeft: "6px",
      width: "80px !important",
      height: "34px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "0.7vw",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "0.9vw",
    },
  },
  toolbar: {
    display: "flex",
  },
}));

const NavbarDesktop = ({ isLogged }) => {
  const { toolbar, menuButton, root } = useStyles();
  const dispatch = useDispatch();

  const { nombre, apellido } = useSelector((state) => state.userInstance);

  const history = useHistory();

  function handleLogout() {
    sessionStorage.removeItem("CURRENT_USER");
    sessionStorage.removeItem('contractSelected');
    dispatch(removeInstance());
    history.push("/");
  }

  const pathname = window.location.pathname;


  return (
    <>
      <AppBar className={"header " + root} position="fixed">
        <Toolbar>
          <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={3} >
                <NavbarLogo />
              </Grid>
              <Grid item xs={6}>
                <NavbarButtons />
              </Grid>
              <Grid item xs={3}>
                  {isLogged ? (
                    <>
                      <span style={{fontSize: "0.85rem"}} className="userName">
                        {nombre} {apellido}
                      </span>
                      <button
                        style={{ color: "#ffffff", borderRadius: 35, backgroundColor: "#00748b", fontSize: 14, margin: "0px 0px auto", padding: "6px 12px"}}
                        onClick={handleLogout}
                        color="inherit"

                      >
                        Cerrar Sesión
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className={menuButton}
                        style={{
                          background: pathname === "/registrar-nuevo-clientes" ? "#ffffff" : "#00748b"
                        }}
                      >
                        <NavLink
                          exact
                          to="/registrar-nuevo-clientes"
                          style={{
                            color:
                              pathname === "/registrar-nuevo-clientes"
                                ? "#00748b"
                                : "#ffffff",
                          }}
                        >
                          Registrarse
                        </NavLink>
                      </button>
                      <button
                        color="inherit"
                        className={menuButton}
                        style={{ marginLeft : '10px'}}
                      >
                        <NavLink
                          exact
                          to="/login-clientes"
                          style={{ color: "#ffffff" }}
                        >
                          Ingresar
                        </NavLink>
                      </button>
                    </>
                  )}     
              </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavbarDesktop;
