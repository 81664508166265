import * as Yup from "yup";
import checkFormModel from "./checkFormModel";
const {
  formField: {
    nombre,
    apellido,
    documento,
    tipo,
    genero,
    fecha,
    email,
    confirmEmail,
    clave,
    code,
    contrato,
    terms,
  },
} = checkFormModel;

const specialChars = /^[^#+()$~%.":;*<>{}@\\/\\]+$/;

export default [
  Yup.object().shape({
    [nombre.name]: Yup.string()
      .matches(specialChars, "Estos caracteres no están permitidos")
      .required(`${nombre.requiredErrorMsg}`),
    [apellido.name]: Yup.string()
      .matches(specialChars, "Estos caracteres no están permitidos")
      .required(`${apellido.requiredErrorMsg}`),
/*     [documento.name]: Yup.string()
      .min(7, "El documento no puede ser inferior a 7 digitos")
      .max(8, "El documento no puede ser mayor a 8 digitos")
      .required(`${documento.requiredErrorMsg}`), */
    [tipo.name]: Yup.string().nullable().required(`${tipo.requiredErrorMsg}`),
    [documento.name]: Yup.string()
      .when(tipo.name, {
        is: 'DU',
        then: Yup.string()
          .matches(/^\d+$/, 'El DNI debe contener solo números')
          .length(8, 'El DNI debe debe tener 8 dígitos')
          .required(documento.requiredErrorMsg),
        otherwise: Yup.string()
          .matches(/^[a-zA-Z0-9]+$/, 'El pasaporte debe ser alfanumérico')
          .length(9, 'El pasaporte debe tener 9 digitos')
          .required(documento.requiredErrorMsg),
      }),
    [genero.name]: Yup.string()
      .nullable()
      .required(`${genero.requiredErrorMsg}`),
    [fecha.name]: Yup.string().nullable().required(`${fecha.requiredErrorMsg}`),
    [email.name]: Yup.string()
      .email("El email no es válido")
      .nullable()
      .required(`${email.requiredErrorMsg}`),
    [confirmEmail.name]: Yup.string()
      .oneOf([Yup.ref("email"), null], "Emails no coinciden")
      .nullable()
      .required(`${confirmEmail.requiredErrorMsg}`),

    // [clave.name]: Yup.string()
    //   .min(8, "La contraseña debe contener al menos 8 caractéres alfanuméricos")
    //   .max(32, "La contraseña no pude superar los 32 caractéres alfanuméricos")
    //   //.matches(/[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/i, "Clave no válida")
    //   //.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character")
    //   .matches(/[a-z]+/, "Falta una minúscula")
    //   .matches(/[A-Z]+/, "Falta una mayúscula")
    //   //.matches(/[@$!%*#?&]+/, "Falta un caracter especial")
    //   .matches(/\d+/, "Falta un número")
    //   .required(`${clave.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [code.name]: Yup.string().required(`${code.requiredErrorMsg}`),
  }),
  // Yup.object().shape({
  //   [terms.name]: Yup.boolean().default(false).literal(true, {
  //     invalid_type_error: "Accept Terms is required",
  //   }),
  // }),
  Yup.object().shape({
    [contrato.name]: Yup.string().required(`${contrato.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [terms.name]: Yup.boolean()
      .required("The terms and conditions must be accepted.")
      .oneOf([true], "The terms and conditions must be accepted."),
  }),
];
