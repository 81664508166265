import * as Yup from 'yup'
import moment from 'moment';
import {
    REQUIRED_NAME_ASESORAMIENTO,
    REQUIRED_LAST_NAME_ASESORAMIENTO,
    REQUIRED_NUMBER_DOCUMENT_ASESORAMIENTO,
    REQUIRED_DATE_ASESORAMIENTO,
    REQUIRED_EMAIL_ASESORAMIENTO,
    VALIDATE_EMAIL_ASESORAMIENTO,
    REQUIRED_COD_AREA_ASESORAMIENTO,
    REQUIRED_TELEFONO_ASESORAMIENTO,
    DATE_MIN_ASESORAMIENTO,
    REQUIERED_TIPO_DOCUMENTO_ASESORAMIETNO,
    MIN_NUMERO_ASESORAMIENTO, MIN_CODAREA_ASESORAMIENTO, MIN_NUMTEL_ASESORAMIENTO

} from '../../../constants/index';

const schemaFormAsesoramiento = Yup.object().shape({
    nombre:Yup.string().required(REQUIRED_NAME_ASESORAMIENTO),
    apellido:Yup.string().required(REQUIRED_LAST_NAME_ASESORAMIENTO),
    docTipo:Yup.string().required(REQUIERED_TIPO_DOCUMENTO_ASESORAMIETNO), 
    /* docNum:Yup.number().required(REQUIRED_NUMBER_DOCUMENT_ASESORAMIENTO).min(8,MIN_NUMERO_ASESORAMIENTO), */
    docNum: Yup.string()
      .when('docTipo', {
        is: 'DU',
        then: Yup.string()
          .matches(/^\d{8}$/, 'El DNI debe contener solo números')
          .required(REQUIRED_NUMBER_DOCUMENT_ASESORAMIENTO),
        otherwise: Yup.string()
          .matches(/^[a-zA-Z0-9]+$/, 'El pasaporte debe ser alfanumérico')
          .length(9, MIN_NUMERO_ASESORAMIENTO)
          .required(REQUIRED_NUMBER_DOCUMENT_ASESORAMIENTO),
    }),

    codigo_nacional:Yup.number().required(REQUIRED_COD_AREA_ASESORAMIENTO).min(2,MIN_CODAREA_ASESORAMIENTO),
    numero:Yup.number().required(REQUIRED_TELEFONO_ASESORAMIENTO).min(8,MIN_NUMTEL_ASESORAMIENTO),
    email:Yup.string().email(VALIDATE_EMAIL_ASESORAMIENTO).required(REQUIRED_EMAIL_ASESORAMIENTO),
    fecha_probable_parto:Yup.date().required(REQUIRED_DATE_ASESORAMIENTO).min(moment().subtract(1, 'days'), DATE_MIN_ASESORAMIENTO),
    observacion:Yup.string(), 
})

export default schemaFormAsesoramiento
